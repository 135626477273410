.certificates {
    margin-bottom: 100px;

    &__body {
        display: flex;
        width: auto;
        margin: 0 -1rem;
        
        &-block {
            padding: 0 1rem;
        }

        @media (max-width: 680px) {
            flex-wrap: wrap;
            margin: 0 -6px;

            &-block {
                width: 50%;
                padding: 0 6px;
                margin-bottom: 1rem;
            }
        }

    }
        
}