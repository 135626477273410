.btn {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    bottom: 50px;
    right: 50px;
    border: none;
    box-shadow: -1px 1px 10px -3px rgb(131, 130, 130);
    cursor: pointer;
    z-index: 100;

    &.visible {
        display: flex;
        @media only screen and (max-width: 700px) {
            display: none!important;
        }
    }

}