.filterSide {
    padding-top: 1.5rem!important;
    width: calc(220px + 1.34rem);
    .title {
        font-weight: 600;
        word-wrap: break-word;
        word-break: break-word;
        font-size: 1.5rem;
    }

    & &__content {
        margin-top: 2.9rem;
    }

    &_mobHeader, &_mobTitle {
        display: none;
    }

    @media only screen and (max-width: 860px) {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        background: #ffffff;
        z-index: 10;

        .title {
            display: none;
        }

        &_mobHeader {
            display: flex;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 60px;
            align-items: center;
            padding: 0px 15px;
            background: #ffffff;
        }

        &_mobTitle {
            display: block;
            font-size: 24px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: calc(15px - 2.9rem);
        }

        &__shown {
            transform: translateX(0px);
            padding-top: 75px !important;
        }
    }
}

.filterCloseBtn {
    background: none;
    border: none;
}