.detaild__wrapper {
    margin-bottom: 11.125rem!important;
}

.filter__list {
    list-style: none;

    & > li {
        .title {
            font-size: 14px;
            margin-bottom: .5rem;
            font-weight: 600;

            .parent {
                text-decoration: none;
                font-weight: 700;
            }
        }
    }

    & > li:not(:last-child) {
        margin-bottom: 1rem;
    }

    & ul {
        list-style: none;
        padding-left: 1rem;

        li {

            .label {
                font-size: 14px;
            }

            a {
                text-decoration: none;
                font-size: 14px;
                
                &.current {
                    font-weight: 700;
                    color: #725aa1;
                }

            }
        }

        li:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}