.form {
    padding: 1em;
    .subtitle {
        margin-bottom: .3em;
        padding: .4em;
        border: 1px dashed #ebe8e8;
        display: inline-block;
        border-radius: 4px;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 10px;
    }

    .input_elem {
        margin-bottom: 2em;
    }



    .agreement__wrap {
        display: flex;
        align-items: baseline;
        margin-bottom: 1em;

        .agreement__text {
            font-size: 0.875rem;
            line-height: 18px;
            color: #7D7D7D;
        }
    }
}