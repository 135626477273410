.blockHeader {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 40px;
    
    @media (max-width: 798px) {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
    }
}