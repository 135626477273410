.productsBlock {
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;

    &_product {
        width: 256px;

        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    &_img{
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        margin-bottom: 16px;
    }

    &_text {
        width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 28px;

        &Link {
            color: #2c2c2c;
            text-decoration: none;
        }
    }

    @media (max-width: 1000px) {
        &_text {
            font-size: 16px;
            line-height: 20px;
        }
    }

    @media (max-width: 680px) {

        justify-content: space-between;
        flex-wrap: wrap;

        &_product {
            width: calc(50% - 7px);
            margin-bottom: 24px;
    
            &:not(:last-child) {
                margin-right: 0px;
            }
        }
        
        &_img{
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
            margin-bottom: 8px;
        }
        
        &_text {
            font-size: 20px;
            line-height: 24px;
        }
    }

    @media (max-width: 460px) {
        &_text {
            font-size: 14px;
            line-height: 19px;
        }
    }
}