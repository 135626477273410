.overlay {
    z-index: 100;
    position: fixed;
    width: auto;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.46);

    .popup {
        padding: .5rem;
        background-color: #fff;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-height: auto;
        overflow-y: auto;
        scrollbar-width: none;
        max-width: 800px;
        top: 8.8vh;
        border-radius: 24px;
        border-radius: clamp(8px,3vw,24px);

        .close_btn {
            position: sticky;
            top: 0;
            left: 100%;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 8px;
            border: none;
            background-color: #fff;
            cursor: pointer;
            box-shadow: 0 0 10px -5px #919090;

            &::after,
            &::before {
                height: 2px;
                border-radius: 50px;
                background-color: #8d8d8d;
                display: block;
                position: absolute;
                content: '';
                top: 50%;
                left: .5rem;
                right: .5rem;  
            }

            &::after {
                transform: translateY(-50%) rotate(45deg);
            }

            &::before {
                transform: translateY(-50%) rotate(-45deg);
            }
        }
    }
}