.advantages {
    margin-bottom: 100px;

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    &_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &_item {
        width: calc(50% - 16px);
    }
    
    @media (max-width: 680px) {
        &_item {
            width: 100%;
        }
    }
}