@import '../../style/color';

.footer {
    height: 160px;
    border-top: 1px solid $border-grey;
    width: 100%;
    padding: 24px calc(50% - 560px) 32px;
    display: flex;
    align-items: center;


    &_logo {
        margin-right: 32px;

        &Img {
            width: 74px;
            height: 92px;
        }
    }

    &_nav {
        max-width: 500px;
        margin-right: auto;

        &List {
            list-style: none;
        }

        &Item {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        &Link {
            text-decoration: none;
            font-size: 16px;
            line-height: 16px;
            color: $text-secondary;
        }
    }

    &_additionalLinks {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    &_copyright {
        font-size: 12px;
        color: $text-secondary;
    }

    @media (max-width: 1180px) {
        padding: 24px 30px;
    }

    @media (max-width: 768px) {
        height: auto;
        padding: 24px 15px;
        flex-direction: column;

        &_logo {
            margin-right: 0px;
            margin-bottom: 24px;
        }
        
        &_nav {
            margin-right: 0px;
            margin-bottom: 24px
        };

        &_additionalLinks {
            height: auto;
            margin-bottom: 24px;
        }
    }
}