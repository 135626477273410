.whereToBuy {
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem;
    margin-bottom: 50px;
    width: auto;

    &_block {
        position: relative;
        width: calc(50% - 16px);
        border-radius: 20px;
        overflow: hidden;
        height: 243px;
        background: #F3E5E5;
        margin: 0 1rem;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,  rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0.8));
        }
    }

    &_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &_text {
        position: absolute;
        bottom: 14px;
        left: 14px;
        width: calc(100% - 150px);
    }

    &_name {
        font-size: 20px;
        margin-bottom: 6px;
        font-weight: 600;
        color: #ffffff;
    }

    &_description {
        color: #ffffff;
    }

    &_button {
        position: absolute;
        bottom: 14px;
        right: 14px;
        width: 136px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: #ffffff;
        border-radius: 5px;
        font-weight: 600;
    }

    @media (max-width: 1130px) {
        flex-wrap: wrap;
        
        &_block {
            width: 100%;
            height: 270px;

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }
}