@import '../../../style/main.scss';

@import './fonts';
@import './normalize';
@import './grid';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

:root {
    font-size: 1em;
    font-size: clamp(0.875em, 3vw, 1em);
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

body #root {
    max-width: 1920px;
    margin: 0 auto;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100vh;
    min-width: 320px;
    color: #2C2D2E;

    footer.footer {
        margin-top: auto;
        background-color: #fff;
    }
}

@for $i from 1 through 6 {
    h#{$i} {
        margin: 0;
        font-size: 2.25rem;
        font-family: OpenSans, Arial, Helvetica, sans-serif;
        font-weight: 700;
        color: #2C2C2C;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    font-size: inherit;
}

.main {
    margin-top: 120px;

    @media (max-width: 900px) {
        margin-top: 60px;
    }
}