.top__banner {
    background: url(./mainBanner.jpg) no-repeat center/cover;
    max-height: 707px;
    height: 36.8229vw;
    min-height: 320px;
    padding: 2rem 0;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        height: 100%;

        @media (max-width:672px) {
            justify-content: flex-start;
        }
    }

    &-col {
        &>* {
            margin-bottom: 1rem;
        }

        h1 {
            font-size: 3.5rem;
            font-size: clamp(1.7rem, 3vw, 3.5rem);
        }

        h2 {
            font-weight: 600;
            font-size: 2rem;
            font-size: clamp(1rem, 3vw, 2rem);
        }

        @media (max-width:672px) {
            position: relative;
            z-index: 0;

            h1 {
                margin-top: 1rem;
            }

            &::before {
                position: absolute;
                z-index: -1;
                display: block;
                width: 100%;
                height: 120%;
                border-radius: 50%;
                background-color: #fff;
                opacity: .7;
                filter: blur(30px);
                content: '';
                left: -20px;
                top: -10px;
            }
        }
    }

    @media (max-width:672px) {
        background-position-x: 100%;
    }

}