.link {
    font-size: 1rem;
    font-weight: 400;
    color: #2c2c2c;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 120px;
    height: 48px;
    border: 1px solid #2c2c2c;
    border-radius: 8px;
    padding: 0 1rem;

    .decor {
        position: relative;
        width: 18px;
        height: 14px;

        span {
            display: block;
            position: absolute;
            width: inherit;
            height: 2px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: #2c2c2c;
            border-radius: 4px;

        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            display: block;
            height: 2px;
            background-color: #2c2c2c;
            border-radius: 4px;
            left: 0;
        }

        &::before {
            width: inherit;
            top: 0;
        }

        &::after {
            width: 12px;
            bottom: 0;
        }
    }

    .text {
        margin-left: 8px;
    }
}