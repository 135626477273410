.title,
.item__text {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1rem;
}

.item__text {
    font-weight: 400;
}

.name {
    color: #7D7D7D;
}

