@import '../../style/color';

.header {
    position: fixed;
    width: 100%;
    height: 120px;
    padding: 0px calc(50% - 560px);
    background: #ffffff;
    display: flex;
    align-items: center;
    z-index: 10;
    border-bottom: 1px solid $border-grey;
    left: 0;
    top: 0;

    &_logo {


        &Img {
            width: 74px;
            height: 92px;
        }

        &__mob {
            display: none;
        }
    }

    &_nav {
        margin-left: auto;
        margin-right: 10px;
    
        &List {
            list-style: none;
            display: flex;
        }

        &Item {


            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        &Link {
            text-decoration: none;
            font-size: 12px;
            line-height: 16px;
            color: $text;
        }
    }

    .linkto__header-catalog {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }

    &_mobCatalogButton,&_mobEmptyBlock  {
        display: none;
    }


    @media (max-width: 1200px) {
        padding: 0px 30px 0px 30px;
        padding: 0px 108px 0px 30px;
    }
    
    @media (max-width: 900px) {
        justify-content: space-between;
        height: 60px;

        &_mobCatalogButton  {
            display: block;
            border: none;
            background: none;
        }

        &_logo, .linkto__header-catalog, &_nav {
            &:not(&__mob) {
                display: none;
            }
        }

        &_logo {
            display: block;
            &__mob {
                img {
                    width: 122.76px;
                    height: 40px;
                }
            }
        }

        &_mobEmptyBlock {
            display: block;
            width: 24px;
        }
    }

    @media (max-width: 768px) {
        padding: 0px 15px;
    }
}

.mobileMenu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 11;
    background: #ffffff;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    &__shown {
        transform: translateX(0px);
    }
    
    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0px 15px;
        margin-bottom: 15px;
    }

    &_content {
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
    }

    &_item {
        text-decoration: none;
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0px 10px;
        background: #F3F3F34D;
        color: #2C2C2C;
        font-size: 16px;
        line-height: 16px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &_search {
        position: fixed;
        width: 100%;
        bottom: 0px;
        left: 0px;
        padding: 15px 15px;
        border-top: 1px solid #F3F3F3;
    }
}