@import '../../style/color';

.contactsBlock {
    display: flex;
    flex-direction: row;

    &_text {
        margin-right: 50px;
    }

    &_map {
        position: relative;
        width: 740px;
        height: 450px;
        border-radius: 24px;
        overflow: hidden;
        border: 1px solid $border-grey;

        .ymaps-2-1-79-gototech {
            display: none;
        }
    }

    @media (max-width: 798px) {
        flex-direction: column;
        
        &_map {
            width: 100%;
            height: 410px;
        }
    }
}