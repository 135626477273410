.title {
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 1.2;
    margin-bottom: 2.5rem;
}

.accordion {
    margin-bottom: 1.5rem;
    max-width: 544px;
}